import React, { useEffect, useCallback, ChangeEvent, useState } from 'react';
import Toggle from 'react-toggle';
import { useDispatch, useSelector } from 'react-redux';
import isHexColor from 'validator/lib/isHexColor';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import { any, isEmpty, isNil, pipe, values } from 'ramda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { requestedUserSettingsUpdateAction } from 'actions/userActions';
import { setCustomizeReportPanelDirty as setCustomizeReportPanelDirtyActionCreator } from 'actions/uiActions';

import { TextField } from 'components/other/TextField';
import { UrlProtocolInput } from 'components/other/UrlProtocolInput';
import { CustomizeReportPanelLogoUpload } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelLogoUpload';
import {
    CustomizeReportFormActionType,
    CustomizeReportFormState,
} from 'components/panels/CustomizeReportPanel/CustomizeReportPanelContainer';

import { customizeReporFetchingSelector, customizeReportIsDirtySelector } from 'selectors/uiSelectors';

interface CustomizeReportFormProps {
    onDispatch: (action: CustomizeReportFormActionType) => void;
    form: CustomizeReportFormState;
}

export const CustomizeReportForm = ({ onDispatch, form }: CustomizeReportFormProps) => {
    const dispatch = useDispatch();
    const isFetching = useSelector<any, boolean>(customizeReporFetchingSelector);
    const isDirty = useSelector<any, boolean>(customizeReportIsDirtySelector);
    const [shouldRemoveLogo, setShouldRemoveLogo] = useState(false);

    const handleWindowClose = (e: any) => {
        if (isDirty || !isEmpty(form.values.logo)) {
            e.preventDefault();
            e.returnValue = true;
        }
    };

    const setCustomizeReportPanelDirty = useCallback(() => {
        dispatch(setCustomizeReportPanelDirtyActionCreator());
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        // NOTE: checking for development because the event messes with webpack reloading
        if (process.env.REACT_WEBPACK_ENV !== 'development') {
            window.addEventListener('beforeunload', handleWindowClose);
            return () => window.removeEventListener('beforeunload', handleWindowClose);
        }
    }, [isDirty, form.values.logo]);

    const handleEnabledToggle = useCallback(() => {
        onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'enabled', value: !form.values.enabled } });
    }, [form.values.enabled]);

    const handleCompanyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomizeReportPanelDirty();
        onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'name', value: e.target.value } });
    };

    const handleCompanyEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomizeReportPanelDirty();
        onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'email', value: e.target.value } });
    };

    const handleCustomDomainChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomizeReportPanelDirty();
        onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'customDomain', value: e.target.value } });
    };

    const handleAccentColorChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCustomizeReportPanelDirty();
        const { value } = e.target;

        if (isHexColor(value)) {
            const valueWithHashtag = value[0] === '#' ? value : `#${value}`;

            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'accentColor', value: valueWithHashtag } });
        } else {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'accentColor', value } });
        }
    };

    const extractHostnameAndPath = (input: string) => {
        try {
            let url
            if (input.startsWith("http")) {
                url = new URL(input);
            } else {
                url = new URL(`https://${input}`)
            }
            return url.hostname;
        } catch {
            return null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const validate = (form => {
        return {
            name: (name: string) => {
                const sanitized = name.replace(/<|>|{|}|\[|]/g, '');

                onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'name', value: sanitized } });

                onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'name' } });

                if (isEmpty(name)) {
                    onDispatch({
                        type: 'SET_FIELD_ERROR',
                        payload: { field: 'name', value: 'Company name is required' },
                    });

                    return true;
                } else {
                    onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'name', value: null } });

                    return false;
                }
            },
            email: (email: string) => {
                onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'email' } });

                if (!isEmpty(email) && !isEmail(email)) {
                    onDispatch({
                        type: 'SET_FIELD_ERROR',
                        payload: { field: 'email', value: "The company email doesn't seem to be valid" },
                    });

                    return true;
                } else {
                    onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'email', value: null } });

                    return false;
                }
            },
            website: (website: string) => {
                onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'website' } });

                if (!isEmpty(website) && !isNil(website) && !isURL(website)) {
                    onDispatch({
                        type: 'SET_FIELD_ERROR',
                        payload: { field: 'website', value: "The company website doesn't seem to be valid" },
                    });

                    return true;
                } else {
                    onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'website', value: null } });

                    if (isEmpty(form.values.email) && !isEmpty(form.values.website)) {
                        onDispatch({
                            type: 'SET_FIELD_VALUE',
                            payload: { field: 'email', value: `@${form.values.website}` },
                        });
                    }

                    return false;
                }
            },

            customDomain: (customDomain: string) => {
                onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'customDomain' } });

                const domainRegex = new RegExp(/^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-_]+\.[a-zA-Z]{2,6}$/);

                if (!isEmpty(customDomain) && !isNil(customDomain) && (!isURL(customDomain) || !domainRegex.test(customDomain))) {
                    onDispatch({
                        type: 'SET_FIELD_ERROR',
                        payload: { field: 'customDomain', value: "The custom domain doesn't seem to be valid" },
                    });

                    return true;
                } else {
                    onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'customDomain', value: null } });
                    return false;
                }
            },
            accentColor: (accentColor: string) => {
                onDispatch({ type: 'SET_FIELD_TOUCHED', payload: { field: 'accentColor' } });

                if (!isEmpty(accentColor) && accentColor.length !== 7 && !isHexColor(accentColor)) {
                    onDispatch({
                        type: 'SET_FIELD_ERROR',
                        payload: { field: 'accentColor', value: "The company color doesn't seem to be valid" },
                    });

                    return true;
                } else {
                    onDispatch({ type: 'SET_FIELD_ERROR', payload: { field: 'accentColor', value: null } });

                    return false;
                }
            },
        };
    })(form);

    const handleCompanyNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        if (value !== e.target.value) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'name', value } });
        }

        validate.name(value);
    };

    const handleCompanyEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        if (value !== e.target.value) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'email', value } });
        }

        validate.email(value);
    };

    const handleAccentColorBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        if (value !== e.target.value) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'accentColor', value } });
        }

        validate.accentColor(value);
    };

    const handleCompanyWebsiteBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        if (value !== e.target.value) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'website', value } });
        }

        validate.website(value);
    };

    const handleCustomDomainBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();

        if (value !== e.target.value) {
            onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'customDomain', value } });
        }

        validate.customDomain(value);
    };

    const handleOnReset = (payload: boolean) => {
        setShouldRemoveLogo(payload);
    };

    const hasError = pipe<any, Array<string | boolean>, boolean>(
        values,
        any(error => !isNil(error)),
    )(form.errors);

    const handleSubmit = () => {
        const emailError = validate.email(form.values.email);

        if (!hasError && !emailError) {
            dispatch(
                requestedUserSettingsUpdateAction({
                    settings: {
                        customDomain: form.values.customDomain,
                        logo: form.values.logoFile,
                        name: form.values.name,
                        website: isEmpty(form.values.website)
                            ? ''
                            : `${form.values.protocol.raw}${form.values.website}`,
                        email: form.values.email,
                        accentColor: form.values.accentColor.replace('#', ''),
                        enabled: form.values.enabled,
                    },
                    removeLogo: shouldRemoveLogo,
                }),
            );
        }
    };

    return (
        // eslint-disable-next-line max-len
        <div className="uk-width-1-1 uk-width-large-4-10 sw-customize-report-form mg-padding-30 uk-overflow-container ln-11">
            <div className="mg-margin-b-15 mg-border-b" style={{ paddingBottom: '15px' }}>
                <div className="uk-flex uk-middle ">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="uk-flex uk-flex-middle font-montserrat font-14 color-grey cursor-pointer">
                        <Toggle
                            className="is-large mg-margin-r-10"
                            checked={form.values.enabled}
                            onChange={handleEnabledToggle}
                        />
                        <span style={{ top: '3px' }} className="uk-position-relative">
                            Whitelabeling is&nbsp;{form.values.enabled ? 'enabled' : 'disabled'}
                        </span>
                    </label>
                </div>
                {!form.values.enabled ? (
                    <div className="mg-alert mg-margin-t-15 is-warning font-14">
                        <FontAwesomeIcon icon="exclamation-triangle" className="mg-margin-r-10" aria-hidden="true" />
                        While disabled, any changes you see below won&apos;t reflect in your reports. Reenable
                        Whitelabeling in order to track changes.
                    </div>
                ) : null}
            </div>
            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="uk-display-inline-block font-montserrat font-14 color-grey mg-margin-b-10">
                    Company logo
                </label>
                <CustomizeReportPanelLogoUpload
                    logo={form.values.logoFile}
                    error={form.errors.logoFile}
                    currentLogoUrl={form.values.logo}
                    onDispatch={onDispatch}
                    onReset={handleOnReset}
                />
            </div>

            <div>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="colorPicker" className="font-montserrat font-14 color-grey">
                    Accent color
                </label>
                <div className="uk-flex uk-flex-top mg-margin-b-30 sw-color-picker">
                    <div className="mg-margin-r-5" style={{ lineHeight: 0 }}>
                        <input
                            className="uk-nbfc"
                            type="color"
                            onChange={handleAccentColorChange}
                            onBlur={handleAccentColorBlur}
                            value={form.values.accentColor}
                            style={{ borderRadius: '4px' }}
                        />
                    </div>
                    <TextField
                        placeholder="e.g. #6e22c6"
                        onChange={handleAccentColorChange}
                        onBlur={handleAccentColorBlur}
                        value={form.values.accentColor}
                        error={form.errors.accentColor}
                        inputContainerClassnames="is-small"
                        inputClassnames="is-small"
                        textFieldWrapperClassnames="uk-flex-item-1"
                    />
                </div>
            </div>
            <TextField
                placeholder="e.g. Cool Agency"
                label="Company name (required)"
                onChange={handleCompanyNameChange}
                value={form.values.name}
                inputContainerClassnames="is-small"
                inputClassnames="is-small"
                onBlur={handleCompanyNameBlur}
                error={form.errors.name}
            />
            <UrlProtocolInput
                error={form.errors.website}
                label="Company website"
                onDispatch={onDispatch}
                placeholder="e.g. coolagency.com"
                protocol={form.values.protocol}
                setCustomizeReportPanelDirty={setCustomizeReportPanelDirty}
                website={form.values.website}
                onBlur={handleCompanyWebsiteBlur}
            />
            <TextField
                placeholder="e.g. info@coolagency.com"
                label="Contact email"
                onChange={handleCompanyEmailChange}
                onBlur={handleCompanyEmailBlur}
                value={form.values.email}
                error={form.errors.email}
                inputContainerClassnames="is-small"
                inputClassnames="is-small"
            />
            <div className="mg-margin-b-30">
                <div
                    className="font-montserrat font-14 color-grey mg-margin-b-5 "
                >
                    Custom domain for reports <strong className="mg-label is-small font-10">NEW</strong>
                </div>
                <TextField
                    textFieldWrapperClassnames="" // to remove bottom padding (default prop overwrite)
                    placeholder="e.g. reports.coolagency.com"
                    onChange={handleCustomDomainChange}
                    value={form.values.customDomain}
                    inputContainerClassnames="is-small"
                    inputClassnames="is-small"
                    onBlur={handleCustomDomainBlur}
                    error={form.errors.customDomain}
                />
                <div className='mg-alert mg-margin-t-15 is-info font-14'>
                    <strong>Setup CNAME record on your domain</strong>
                    <ol>
                        <li>Create a <strong className='color-black'>CNAME</strong> record for your website <i>{extractHostnameAndPath(form.values.customDomain) || "e.g., reports.coolagency.com"}</i></li>
                        <li>Add <code>whitelabel.mangools.com</code> as a value</li>
                        <li>Wait. It can take about an hour to take effect</li>
                    </ol>
                    <a href="https://mangools.com/blog/serpwatcher-guide/#whitelabel" target="_blank" rel="noopener">Learn more</a>
                </div>
            </div>
            <div className="mg-margin-b-30">
                <button
                    className="mg-btn is-green uk-width-1-1 is-small"
                    onClick={handleSubmit}
                    type="button"
                    disabled={hasError || isFetching}
                >
                    {isFetching ? (
                        <span>
                            <FontAwesomeIcon icon="spinner" spin />
                            <span>&nbsp;Saving...</span>
                        </span>
                    ) : (
                        <span>Save settings</span>
                    )}
                </button>
            </div>
        </div>
    );
};
